<template>
  <layout-horizontal-main>
    <router-view />
  </layout-horizontal-main>
</template>

<script>
import LayoutHorizontalMain from '@/layouts/horizontal/LayoutHorizontalMain.vue'

export default {
  name: 'Main',
  components: {
    LayoutHorizontalMain,
  },
}
</script>

<style>

@media print {
  body{
    height: 100vh !important;
  }

  td {
    border: 1px solid #000 !important;
    border-bottom: none !important;
  }
  tr:last-child td {
    border-bottom: 1px solid #000 !important;
  }

}

</style>
